import { useEffect, useRef, useState } from "react";

// Temporary shorter intervals for testing
const VERSION_CHECK_INTERVAL = 30 * 1000; // Check every 30 seconds
const MINIMUM_CHECK_INTERVAL = 15 * 1000; // Minimum 15 seconds between checks

export function VersionChecker() {
  const lastCheckTime = useRef(Date.now());
  const lastVersion = useRef(null);
  const [currentVersion, setCurrentVersion] = useState(null);

  useEffect(() => {
    const version = document.querySelector('meta[name="app-version"]')?.content;

    setCurrentVersion(version);
    console.log("VersionChecker initialized with version:", version);

    // Store initial version
    if (!lastVersion.current) {
      lastVersion.current = version;
      console.log("Initial version set:", lastVersion.current);
    }

    const checkVersion = async () => {
      // Prevent checks that are too frequent
      const now = Date.now();
      if (now - lastCheckTime.current < MINIMUM_CHECK_INTERVAL) {
        console.log("Skipping check - too soon since last check");
        return;
      }

      try {
        lastCheckTime.current = now;
        console.log("Checking for new version...");

        // Fetch the index.html with a cache-busting query parameter
        const response = await fetch(`/index.html?_=${now}`, {
          headers: {
            "Cache-Control": "no-cache",
            Pragma: "no-cache",
          },
        });

        if (!response.ok) {
          console.error("Failed to fetch version info:", response.status);
          return;
        }

        const text = await response.text();

        // Extract version from the fetched HTML
        const match = text.match(/content="(\d+)".*app-version/);
        const newVersion = match?.[1];

        console.log("Version check:", {
          version,
          lastVersion: lastVersion.current,
          newVersion,
          timestamp: new Date().toISOString(),
        });

        // Only reload if we have valid versions and they're different
        if (
          newVersion &&
          lastVersion.current &&
          newVersion !== lastVersion.current &&
          newVersion !== version
        ) {
          console.log("New version detected - reloading application:", {
            current: version,
            last: lastVersion.current,
            new: newVersion,
          });
          window.location.reload(true);
        }
      } catch (error) {
        console.error("Version check failed:", error);
      }
    };

    // Check version periodically
    const interval = setInterval(checkVersion, VERSION_CHECK_INTERVAL);
    console.log(
      "Version check interval set:",
      VERSION_CHECK_INTERVAL / 1000,
      "seconds"
    );

    // Initial check after a short delay
    const initialCheck = setTimeout(() => {
      console.log("Running initial version check...");
      checkVersion();
    }, 5000);

    return () => {
      console.log("Cleaning up VersionChecker...");
      clearInterval(interval);
      clearTimeout(initialCheck);
    };
  }, []);

  // Add a small version display in the corner
  return currentVersion ? (
    <div
      style={{
        position: "fixed",
        bottom: "10px",
        right: "10px",
        background: "#e0e0e0",
        border: "1px solid #ccc",
        padding: "8px 12px",
        borderRadius: "5px",
        fontSize: "12px",
        opacity: "0.9",
        zIndex: 9999,
        boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
        display: "flex",
        flexDirection: "column",
        gap: "4px",
      }}
    >
      <div>
        Build Time:{" "}
        {new Date(parseInt(currentVersion) * 1000).toLocaleTimeString()}
      </div>
      <div style={{ fontSize: "10px", color: "#666" }}>Version Test 1.0</div>
    </div>
  ) : null;
}

export default VersionChecker;
